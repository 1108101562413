import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="resizeable"
export default class extends Controller {
  static targets = ['handle']
  connect() {
    if (!this.element.id) throw new Error('Resizeable elements must have an id');
    let localeStorageKey = this.element.id;
    let deltaX = 0;
    let originX = 0;
    let initialWidth = 0;
    const container = this.element;
    let handle = this.handleTarget;
    function handleMove(event) {
      const newValue = Math.round(event.clientX - originX);
      if (newValue === deltaX) return;
      deltaX = newValue;
      requestAnimationFrame(() => {
        container.style.width = `${initialWidth + deltaX}px`;
      });
    }
    let navigationWidth = localStorage.getItem(localeStorageKey);
    if (navigationWidth) {
      container.style.width = `${navigationWidth}px`;
    }
    handle.addEventListener('mousedown', (event) => {
      event.preventDefault();
      originX = event.clientX;
      initialWidth = container.offsetWidth;
      document.addEventListener('mousemove', handleMove);
      const handleMouseUp = () => {
        localStorage.setItem(localeStorageKey, container.offsetWidth);
        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleMouseUp);
      }
      document.addEventListener('mouseup', handleMouseUp);
    });
  }
}
